<template>
  <div class="examlist-layout">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item :to="{ path: '/manage/paper' }">试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/paper/list/${$route.query.paperBankId}` }"
          >试卷库管理({{ $route.query.tabLabel }})</el-breadcrumb-item
        >
        <el-breadcrumb-item>查看所有考试</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 100%;padding: 15px;">
      <div class="bg-shadow func-bar">
        <div class="batchDele">
          <Button @click="checkLength" class="add-btn" type="primary">
            <svg class="icon spread" aria-hidden="true">
              <use xlink:href="#icon-shanchu" />
            </svg>
            删除
          </Button>
        </div>
        <div class="search-container">
          <Input class="searchInput" v-model="searchContent" search placeholder="请输入考试名称" />
        </div>
      </div>
      <div class="ttable-wrapper">
        <Table :columns="columns" :data="tableData" :loading="loading" @on-selection-change="selectedChange">
          <template slot-scope="{ row, index }" slot="name">
            <div class="nowrap" :title="row.name">{{ row.name }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="paper">
            <div class="nowrap" :title="row.paper">{{ row.paper }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="description">
            <div :title="row.description">{{ row.description }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="limitTime">
            <div>{{ row.limitTime }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="condition">
            <div>{{ row.condition }}</div>
          </template>
          <template slot-scope="{ row, index }" slot="creatorName">
            <div class="nowrap" :title="row.creatorName">
              {{ row.creatorName }}
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="createdTime">
            <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd') }}</span>
          </template>
          <template slot-scope="{ row, index }" slot="pt">
            <div class="row-action-container">
              <Icon
                title="删除"
                aria-hidden="true"
                style="cursor: pointer;font-size: 18px"
                type="ios-trash-outline"
                @click.stop="handleClickMenu('delete', row)"
              />
              <Icon
                title="修改"
                aria-hidden="true"
                style="cursor: pointer; font-size: 18px"
                type="ios-create-outline"
                @click.stop="
                  paperBank = row
                  modifyPaperBankModal = true
                "
              />
            </div>
          </template>
        </Table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="page + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="size"
          show-elevator
          show-sizer
          show-total
          @on-change="changePage"
          @on-page-size-change="changeSize"
        />
      </div>
    </div>
    <paperExaminerModal :is-add="false" v-model="modifyPaperBankModal" :paper-bank="paperBank" @on-success="handleOperationSuccess" />
    <DeleteModal v-model="deleteVisible" title="确认删除考试吗？" :loading="deleteLoading" @on-ok="deleteExam()">
      <span>删除考试后不可恢复，你还要继续吗？</span>
    </DeleteModal>
  </div>
</template>

<script>
import { getStatusByValue, EXAM_STATUS } from '@/util/examStatus'
import examApi from '@api/exam'
import paperAnswerDetail from '../paper/paperAnswerDetail'
import DeleteModal from '../../../common/DeleteModal'
import paperExaminerModal from '../paper/paperExaminerModal'
import { throttle } from '@util/throttle'
import exam from '../../../../api/exam'
import { mapMutations } from 'vuex'

export default {
  components: { DeleteModal, paperAnswerDetail, paperExaminerModal },
  name: 'paperDesignated',
  data() {
    return {
      selectedExamId: [],
      loading: false,
      showAllocateModal: false,
      deleteVisible: false,
      deleteLoading: false,
      showRel: false,
      postId: '',
      modifyPaperBankModal: false,
      searchContent: '',
      paperBank: {
        type: Object,
        required: true
      },
      selectedExam: {},
      isSpread: false,
      tableData: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '考试名称',
          slot: 'name',
          className: 'name-column',
          tooltip: true
        },
        {
          title: '试卷名称',
          slot: 'paper',
          align: 'center',
          className: 'paper-column'
        },
        {
          title: '考试简介',
          align: 'center',
          slot: 'description'
        },
        {
          title: '考试时长(分钟)',
          slot: 'limitTime',
          align: 'center',
          sortable: true
        },
        {
          title: '考试通过比例(0-1)',
          align: 'center',
          slot: 'condition'
        },
        {
          title: '发布时间',
          align: 'center',
          slot: 'createdTime',
          sortable: true
        },
        {
          title: '发布人',
          align: 'center',
          key: 'creatorName',
          slot: 'creatorName',
          className: 'count-column',
          sortable: true
        },
        {
          title: '操作',
          slot: 'pt',
          width: 100,
          className: 'opt-column'
        }
      ],
      page: 0,
      size: 10,
      total: 0,
      refresh: false // 是否批量删除(true 否)
    }
  },
  created() {
    this.searchData(true)
    this.$watch(
      'searchContent',
      throttle(newQuery => {
        this.$emit('searchContent', newQuery)
        this.searchData(true)
      }, 2000)
    )
  },
  computed: {
    EXAM_STATUS() {
      return EXAM_STATUS
    }
  },
  methods: {
    checkLength() {
      if (this.selectedExamId.length > 0) {
        this.deleteVisible = true
      } else {
        this.$message.warning('请选择要删除的考试')
      }
    },
    initTableData(page, size) {
      this.loading = true
      exam
        .getPaperBankExams(page, size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchData(refresh = false) {
      const page = this.page
      const size = this.size
      let searchContent = this.searchContent
      let sort = ''
      // this.loading = true
      let paperBankId = this.$route.query.paperBankId
      this.isSpread = false
      if (refresh) {
        exam.getPaperBankExams(paperBankId, sort, page, size, searchContent).then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.loading = false
        })
      }
    },
    selectedChange(val) {
      this.selectedExamId = val.map(item => {
        return item.examId
      })
    },
    changePage(val) {
      this.page = val - 1
      this.searchData(true)
    },
    changeSize(val) {
      this.size = val
      this.searchData(true)
    },
    getStatus(status) {
      return getStatusByValue(status)
    },
    handleClickMenu(name, row) {
      switch (name) {
        case 'delete':
          this.handleDeleteExam(row)
          break
        case 'preview':
          this.handlePreviewExam(row)
          break
        case 'remark':
          this.showAllocateModal = true
          break
        default:
          break
      }
    },
    updateExam(row) {
      this.courseIds = []
      this.courseIds.push(row.courseId)
      this.showRel = false
    },
    handleOperationSuccess(name, res) {
      // this.$emit(name, res)
    },
    handlePreviewExam(row) {
      this.$router.push(`/manage/exam/detail/${row.examId}`)
    },
    handleDeleteExam(row) {
      this.selectedExamId = [row.examId]
      this.deleteVisible = true
    },
    deleteExam() {
      this.deleteLoading = true
      examApi
        .delExam(this.selectedExamId)
        .then(() => {
          this.$message.success('删除成功')
          this.selectedExamId = []
          this.searchData(true)
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.examlist-layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  .func-bar {
    //margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    position: relative;
    display: flex;

    .batchDele {
      width: 50%;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .filter-btn {
      margin-right: 20px;
      min-width: 100px;
    }

    .search-container {
      width: 50%;
      float: right;
      margin-top: 10px;
      text-align: right;
      margin-right: 42px;
      .search {
        width: 60px;
      }
      .searchInput {
        width: 280px;
      }
      .searchInput ::v-deep .ivu-input {
        border: 0;
        display: inline-block;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }

      .advanced-container {
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        font-size: 14px;
        padding-right: 20px;

        svg.icon {
          transition: all @default-transition;
          transform: rotate(0deg);
          margin-left: 8px;
        }

        svg.icon.spread {
          transform: rotate(180deg);
        }
      }

      .advanced-board {
        position: absolute;
        z-index: 100;
        top: 56px;
        right: 0;
        width: 390px;
        height: 0;
        overflow: hidden;
        border-right: solid 5px @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: all @default-transition;

        &.show {
          height: 250px;
        }

        .search {
          margin-right: 20px;
          height: 40px;
          border-radius: 4px;
        }

        .form {
          width: 330px;
          margin-top: 20px;
          ::v-deep .ivu-form-item-label {
            width: 60px !important;
          }
          ::v-deep .ivu-form-item-content {
            display: flex;
          }
          ::v-deep .ivu-input {
            width: 250px;
          }
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    ::v-deep .ivu-table {
      height: inherit;
      .opt-column {
        .operation {
          cursor: pointer;
          color: @primary-color;
          transition: all @default-transition;

          &:hover {
            color: @primary-hover-color;
          }
        }
      }
    }
    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .name-column {
        width: 20%;
      }
      .fileName-column {
        width: 30%;
      }
      .type-column {
        width: 15%;
      }
      .time-column {
        width: 15%;
        flex: 1 1 auto;
      }
      .ivu-table-body {
        cursor: pointer;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  ::v-deep .ivu-table-overflowX {
  }
  .row-action-container * + * {
    margin-left: 10px;
  }
  ::v-deep .ivu-table-cell {
    white-space: inherit;
  }
}
.table-wrapper {
  padding: 0;
  ::v-deep .flex-table {
    width: 100%;
    .select-column {
      width: 35px;
    }
    .paper-name-column {
      width: 15%;
      flex: 1 1 auto;
    }
    .name-column {
      width: 10%;
    }
    .time-column {
      width: 9%;
    }
    .num-column {
      width: 7%;
    }
    .count-column {
      width: 8%;
    }
  }
}
</style>
